import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import birthdayToAge from "../function/birthdayToAge.js";
import getUrl from '../function/getUrl'
import reloadIcon from "../icon/reload.svg";
import searchIcon from "../icon/search.svg";
import checkLogin from "../function/checkLogin.js"
import getDay from "../function/getDay";
import CoachDashBoard from "../pages/coachDashBoard.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as xlsx from 'xlsx/xlsx.mjs';


const url = getUrl()
const token = sessionStorage.getItem("token")
//月銷售報表
const MonthlyReport = (props) => {
  let today = getDay(0, "-");
  // let getMonth=today.substring(0,7)+"-01"
  const [getMonth, setGetMonth] = useState(today.substring(0, 7) + "-01")
  const [startDate, setStartDate] = useState(new Date());
  console.log("getMonth", getMonth)
  const [orderTotal, setOrderTotal] = useState(0)
  const [productTotal, setProductTotal] = useState(0)
  const [total, setTotal] = useState(0)
  const [productData, setProductData] = useState([])
  const [saleData, setSaleData] = useState([])
  const [coachData, setCoachData] = useState([])
  const [trialToOrder, setTrialToOrder] = useState([])
  const [trialOrder, setTrialOrder] = useState([])
  const [analyst, setAnalyst] = useState([])
  const [cancelList, setCancelList] = useState([])
  const [trialOrderTotal,setTrialOrderTotal]=useState(0)
  const [salesList,setSaleList]=useState([])
  const [goalData, setGoalData] = useState(null);
  //設計功能切換

  //進度條


  var imgStyle = {
    width: "20px",
    height: "20px",
  };


  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  const update = async (getMonth) => {
    console.log(getMonth)
    let data = await fetch(`${url}/appGet/monthlyReport?token=${token}&getMonth=${getMonth}`).then(res => {
      return res.json()
    }).then(json => {
      console.log(json)

      return json
    })
    
    let tempGoal = (
      // <>
      // <div className="container bg-light p-1 rounded">
      // <table>
      // <thead>
      //     <tr>
      //       <th className="p-1 text-info">該月課程銷售目標</th>
      //       <th className="p-1 text-dark" cols="4">${data[7].goal}</th>
      //     </tr>
      //     </thead>
      // </table>
      //   <table className="table table-striped p-1 mb-3 text-center">
     
      //     <tbody>

      //     <tr className="p-1 ">
      //          <td className="p-1 border border-muted">成長率</td>
             
      //          {
      //      data[7].goalDetail.map(data=>{
      //         return (<>
             
      //          <td className="p-1 border border-muted">{data.parcentage}%</td>
          
        
      //       </>
      //         )
      //       })
      //     }
      //       </tr>
      //       <tr className="p-1">
      //          <td className="p-1  border border-muted">目標</td>
      //          {
      //       data[7].goalDetail.map(data=>{
      //         return (
      //          <td className="p-1 border border-muted">{data.goal}</td>
       
      //         )
      //       })
      //     }
      //       </tr>
         
      //     </tbody>
      //   </table>
      //   </div>
      // </>
      <>

      <div className="d-block d-sm-none ">
        <table>
        <thead>
            <tr>
              <th className="p-1 text-info">本月目標</th>
              <th className="p-1 text-dark" cols="4"><h5>{ data[7].goal}</h5></th>
            </tr>
            </thead>
        </table>
      
          <table className="text-center" style={{"width":"100%"}}>
             <tr>
              <td >
                <tr><td>成長率</td></tr>
                          {
             data[7].goalDetail.map(data=>{
                return (
               
                <tr > <td >{data.parcentage}%</td></tr>
            
                )
              })
            }</td>
            
              <td >
              <tr ><td>目標</td></tr>
                
                {
              data[7].goalDetail.map(data=>{
                return (
                 <tr><td className="text-center" >{data.goal}</td></tr>
         
                )
              })
            }
            </td>
              </tr> 
         

         

            
           
        
          </table>
          </div>
          
          <div className="d-none d-sm-block">
        <table>
        <thead>
            <tr>
              <th className="p-1 text-info">本月目標</th>
              <th className="p-1 text-dark" cols="4">{ data[7].goal}</th>
            </tr>
            </thead>
        </table>
      
          <table className="table table-striped p-1 mb-3 text-center">
        
        
            <tbody>

            <tr className="p-1 ">
                 <td className="p-1 border border-muted">成長率</td>
               
                 {
               data[7].goalDetail.map(data=>{
                return (<>
               
                 <td className="p-1 border border-muted">{data.parcentage}%</td>
            
          
              </>
                )
              })
            }
              </tr>
              <tr className="p-1 ">
                 <td className="p-1  border border-muted">目標</td>
                 {
               data[7].goalDetail.map(data=>{
                return (
                 <td className="p-1 border border-muted  " >{data.goal}</td>
         
                )
              })
            }
              </tr>
           
            </tbody>
          </table>
          </div>
        </>
    );

    setGoalData(tempGoal);

    let coachData = await fetch(`${url}/appGet/getCoachDashBoard?token=${token}&getMonth=${getMonth}`).then(res => {
      return res.json()
    }).then(json => {
      console.log(json)
      return json
    })
    console.log("data[2]", data[2])
    setSaleData(data[0])
    setProductData(data[1])
    setTrialToOrder(data[2])
    setAnalyst(data[3])
    setCancelList(data[4])
    setTrialOrder(data[5])
    setSaleList(data[6])
    let tempOrderTotal=0
    let tempProductTotal = 0
    let tempTrialOrderTotal=0
    for (let i in data[0]) {
      tempOrderTotal = tempOrderTotal + parseInt(data[0][i]["total"], 10)

    }
    for (let i in data[1]) {
      tempProductTotal = tempProductTotal + parseInt(data[1][i]["total"], 10)

    }
    for (let i in data[5]) {
      tempTrialOrderTotal = tempTrialOrderTotal + parseInt(data[5][i]["total"], 10)
     
    }
    setOrderTotal(tempOrderTotal)
    setProductTotal(tempProductTotal)
    setTotal(tempOrderTotal + tempProductTotal+tempTrialOrderTotal)
    setCoachData(coachData)
    setTrialOrderTotal(tempTrialOrderTotal)
  }

  const DownloadExcel=(props)=>{
    const downloadDateForExcel=()=>{
      let fileName = `Sales統計資料`;
const columns = ["日期","發票號碼","名稱","數量","單價","總金額","付款方式","學員","暱稱"]
  let dataArray=[]
  dataArray.push(columns)
  
      
    for ( let x in salesList){
      console.log(x,salesList[x])
      let tempDatas = [
        salesList[x]["orderDate"],
        salesList[x]["invoiceID"],
        salesList[x]["name"],
        salesList[x]["amount"],
        salesList[x]["price"],
        salesList[x]["total"],
        salesList[x]["payment"],
        salesList[x]["student"],
       salesList[x]["nickname"],
      
]
    dataArray.push(tempDatas)
  }
let jsonWorkSheet = xlsx.utils.aoa_to_sheet(dataArray);
let workBook = {
  SheetNames: [ 'jsonWorkSheet'],
  Sheets: {
    'jsonWorkSheet': jsonWorkSheet,
  }
};
xlsx.writeFile(workBook, `${fileName}.xlsx`);
         
    }

    return (
      <>
          <div className="btn btn-info" onClick={downloadDateForExcel}> 下載銷售明細 
            
          </div>
        </>
    )
  }
  useEffect(async () => {

    update(getMonth)

  }, []);

  return (
    <>

      <div className="card mt-2" style={{ width: "18rem;" }}>

        <div className="card-body alert-danger">

          <h4 className="card-title">月營收總數 ${total} </h4>
     
          <small>計算區間為查詢起始日期為該月1日至該月底</small>
          <div className="row">
            <div className="col-12 col-sm-8">
                <div className="input-group input-group-default mb-3" style={{ "width": "100%" }}>
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-default">查詢月份</span>

                  {/* <input type="date" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" value={getMonth} onChange={(e) => {
                  setGetMonth(e.target.value)
                  update(e.target.value)
                }} /> */}
                  <DatePicker className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" selected={startDate} onChange={(date) => {
                    setStartDate(date)
                    //  let tempDate=date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear()
                    console.log(formatDate(date));
                    let tempDate = formatDate(date)
                    update(tempDate)
                    console.log(date)
                  }} dateFormat="yyyy/MM" showMonthYearPicker />
                </div>
              </div>
            </div>
            <div className="col-4 col-sm-4">
            <div className="mb-2 text-right">
              <div className="d-none d-sm-block">
              <DownloadExcel/>
              </div>
        
            </div>
            </div>
          </div>
        
          
        
          <div className="mb-1 bg-light">
          {goalData}
          </div>
         
         
          <div className="card-text alert-light rounded p-2">
            <h5 className="card-title text-right">課程銷售總額 $ {orderTotal}</h5>
            <table className="table table-striped">
              <thead>
                <tr className="text-center">
                  <th >專案名稱</th>
                  <th   >訂單筆數</th>
                  <th  >堂數</th>
                  <th >合計</th>

                </tr>
              </thead>
              <tbody>
                {
                  saleData.map((item, index) => {
                    if (item.total==0){
                      return
                    }
                    return (
                      <tr className="text-center">

                        <td>{item.eventName}</td>
                        <td >{item.count}</td>
                        <td >{item.amount}</td>
                        <td >{item.total}</td>
                      </tr>
                    )

                  })
                }

              </tbody>
            </table>

          </div>

          <div className="mt-2 card-text alert-light rounded p-2">
            <h5 className="card-title text-right">體驗銷售總額 $ {trialOrderTotal}</h5>
            <table className="table table-striped">
              <thead>
                <tr className="text-center">
                  <th >專案名稱</th>
                  <th   >訂單筆數</th>
                  <th  >堂數</th>
                  <th >合計</th>

                </tr>
              </thead>
              <tbody>
                {
                  trialOrder.map((item, index) => {
                   
                    return (
                      <tr className="text-center">

                        <td>{item.eventName}</td>
                        <td >{item.count}</td>
                        <td >{item.amount}</td>
                        <td >{item.total}</td>
                      </tr>
                    )

                  })
                }

              </tbody>
            </table>

          </div>
          <div className="card-text alert-light rounded p-2 mt-2">
            <h5 className="card-title text-right">商品銷售總額 $ {productTotal}</h5>
            <table class="table table-striped">
              <thead>
                <tr className="text-center">
                  <th scope="col">商品名稱</th>
                  <th scope="col">訂單數</th>
                  <th scope="col">數量</th>
                  <th scope="col">合計</th>

                </tr>
              </thead>
              <tbody className="text-center">
                {productData.map(item => {

                  let single = (
                    <>
                      <tr

                        className="text-muted"
                        style={{ textDecoration: "none" }}
                      >
                        <td>{item["name"]}</td>
                        <td>{item["amount"]}</td>
                        <td>{item["count"]}</td>
                        <td>{item["total"]}</td>

                      </tr>
                    </>
                  )

                  return single
                })


                }
              </tbody>
            </table>

          </div>

          <h5 className="card-title mt-5"> 教練授課 即時指標</h5>
          <div className="card-text alert-light rounded p-2">
            <table className="table  table-striped">
              <thead>
                <tr>

                  <th className=" px-0 text-center" >姓名</th>
                  <th className="px-0 text-center" >正課</th>
                  <th className="px-0 text-center" >正課合格</th>
                  <th className="px-0 text-center" >助教</th>
                  <th className="px-0 text-center" >合格堂數</th>
                  <th className="px-0 text-center" >進度回饋<br /><small>完成 / 學員人次</small></th>
                </tr>
              </thead>
              <tbody>
                {
                  coachData.map((item, index) => {
                    return (
                      <tr onClick={() => {
                        window.location.href = `/coachClassListThisMonth/${item.staffDBID}`
                      }}>

               
                         

                        <td className=" px-1 text-center">{item.name}</td>
                        <td className=" px-1 text-center">{item.coachAmount}-({item.coachWeekEndCount}:{parseInt(item.coachAmount)-parseInt(item.coachWeekEndCount)})</td>
                        <td className=" px-1 text-center">{item.feedAmountByDone}</td>
                        <td className=" px-1 text-center">{item.assistantAmount}-({item.assistWeekEndCount}:{parseInt(item.assistantAmount)-parseInt(item.assistWeekEndCount)})</td>
                        <td className=" px-1 text-center">{parseInt(item.feedAmountByDone, 10) + parseInt(item.assistantAmount, 10) / 2}</td>
                        <td className=" px-1 text-center">{item.amount} / {item.doneAmount}</td>

                      </tr>
                    )
                  })
                }

              </tbody>
            </table>
          </div>

          <h5 className="card-title mt-5"> 櫃檯 即時指標: 體驗轉正式 共{trialToOrder.length} 筆</h5>
          <div className="card-text alert-light rounded p-2">
            <table className="table  table-striped">
              <thead>
                <tr>

                  <th className=" px-0 text-center" >劃位總數</th>
                  <th className=" px-0 text-center" >完課</th>
                  <th className="px-0 text-center" >取消</th>
                  <th className="px-0 text-center" >融通取消</th>
                  <th className="px-0 text-center" >融通%</th>
                  <th className="px-0 text-center" >提供證明</th>
                  <th className="px-0 text-center" >證明%</th>
                </tr>
              </thead>
              <tbody>
                {
                  analyst.map((item, index) => {
                    return (
                      <tr  >

                        <td className=" px-1 text-center">{item.total}</td>
                        <td className=" px-1 text-center">{item.done}</td>
                        <td className=" px-1 text-center">{item.cancel}</td>
                        <td className=" px-1 text-center">{item.cancelByProve} </td>
                        <td className=" px-1 text-center">{Math.round((item.cancelByProve-item.cancelByProveWithProve) / (item.total) * 100)}%<br />
                          <small>融通取消-提供證明 / 總劃課數</small>
                        </td>
                        <td className=" px-1 text-center">{item.cancelByProveWithProve}</td>
                        <td className=" px-1 text-center">{Math.round(item.cancelByProveWithProve / item.cancelByProve * 100)}%
                          <br />
                          <small>提供證明 / 融通取消</small></td>


                      </tr>
                    )
                  })
                }

              </tbody>
            </table>
          </div>
          <div className="card-text alert-light rounded p-2">
          <h5 className="card-title mt-2"> 本月融通取消名單</h5>
            <table className="table  table-striped">
              <thead>
                <tr>

                  <th className=" px-0 text-center" >姓名</th>
                  <th className=" px-0 text-center" >6個月內累積融通取消次數</th>
                </tr>
              </thead>
              <tbody>
                {
                  cancelList.map((item, index) => {
                    return (
                      <tr  onClick={() => {
                        window.location.href = `/SingleStudentView/${item.studentDBID}`
                      }}>

                        <td className=" px-1 text-center">{item.name}</td>
                        <td className=" px-1 text-center">{item.countNumber}</td>


                      </tr>
                    )
                  })
                }

              </tbody>
            </table>
          </div>
          <div className="card-text alert-light rounded p-2">
            <table className="table  table-striped">
              <thead>
                <tr>

                  <th className=" px-0 text-center" >學員姓名</th>
                  <th className="px-0 text-center" >訂單日期</th>
                  <th className="px-0 text-center" >體驗日期</th>
                  <th className="px-0 text-center" >日期差</th>
                  <th className="px-0 text-center" >訂單金額</th>
                  <th className="px-0 text-center" >方案內容</th>
                </tr>
              </thead>
              <tbody>
                {
                  trialToOrder.map((item, index) => {
                    return (
                      <tr onClick={() => {
                        window.location.href = `/orderView/${item.orderDBID}`
                      }}>

                        <td className=" px-1 text-center">{item.name}</td>
                        <td className=" px-1 text-center">{item.orderDate}</td>
                        <td className=" px-1 text-center">{item.trialDate}</td>
                        <td className=" px-1 text-center">{item.diffDay}</td>
                        <td className=" px-1 text-center">{item.eventPrice}</td>
                        <td className=" px-1 text-center">{item.eventName}</td>

                      </tr>
                    )
                  })
                }

              </tbody>
            </table>
          </div>
        </div>
      </div>


    </>
  );
};

export default MonthlyReport;
