import React, {
  useState,
  useEffect,
  useCallback,
  updateState,
  useReducer,
  useContext,
} from "react";

import getDay from "../function/getDay";
import getUrl from "../function/getUrl";
import BackupToBooking from "../components/backupToBooking"
import checkLogin from "../function/checkLogin.js";
import GetStudentTrialListForHome from "../pages/getStudentTrialListForHome"

const CoachDashBoard = (props) => {
  const url = getUrl();
  const getMonth=props.getMonth
  const token = sessionStorage.getItem("token");

  const [coachData,setCoachData]=useState([])

  let today = getDay(0, "-");
  if (!checkLogin()) {
    window.location.href = "/logout";
  }

  const  getData= async ()=>{
    fetch(`${url}/appGet/getCoachDashBoard?token=${token}&getMonth=${getMonth}`).then(res=>{
      return res.json()
    }).then(json=>{
      console.log(json)
      return json
    })
  }

  useEffect( async () => {
  
    let data=await fetch(`${url}/appGet/getCoachDashBoard?token=${token}&getMonth=${getMonth}`).then(res=>{
      return res.json()
    }).then(json=>{
      console.log(json)
      return json
    })
    setCoachData(data)
   
  
  }, []);
  //Home
  return (
    <>
  {getMonth}
              <h5 className="card-title"> 教練授課 即時指標</h5>
              <div className="card-text alert-light rounded p-2">
                <table className="table  table-striped">
                  <thead>
                    <tr>
                    
                      <th className=" px-0 text-center" >姓名</th>
                      <th  className="px-0 text-center" >正課</th>
                      <th  className="px-0 text-center" >正課合格</th>
                      <th  className="px-0 text-center" >助教</th>
                      <th  className="px-0 text-center" >合格堂數</th>
                      <th  className="px-0 text-center" >進度回饋<br/><small>完成 / 學員人次</small></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      coachData.map((item,index)=>{
                        return (
                          <tr onClick={()=>{
                            window.location.href=`/coachClassListThisMonth/${item.staffDBID}`
                          }}>
                         
                          <td className=" px-1 text-center">{item.name}</td>
                          <td className=" px-1 text-center">{item.coachAmount}-({item.coachWeekEndCount}:{parseInt(item.coachAmount)-parseInt(item.coachWeekEndCount)})</td>
                          <td className=" px-1 text-center">{item.feedAmountByDone}</td>
                          <td className=" px-1 text-center">{item.assistantAmount}-({item.assistWeekEndCount}:{parseInt(item.assistantAmount)-parseInt(item.assistWeekEndCount)})</td>
                          <td className=" px-1 text-center">{parseInt(item.feedAmountByDone,10)+parseInt(item.assistantAmount,10)/2}</td>
                          <td className=" px-1 text-center">{item.amount} / {item.doneAmount}</td>
                         
                        </tr>
                        )
                      })
                    }
                    
                  </tbody>
                </table>
              </div>
           
    </>
  );
};

export default CoachDashBoard;
